import LayoutComponent from "../components/layout/LayoutComponent";
import React, {useCallback, useEffect, useState} from "react";
import '../styles/Menu.css';
import Footer from "../components/Footer";

function Booking(props) {

    useEffect(() => {
        window._hors = [['eid', 'hydra-9d590097-0512-4cac-b8f8-985d65b796ff'], ['tagid', 'hors-hydra-9d590097-0512-4cac-b8f8-985d65b796ff'], ['width', '100%'], ['height', ''], ['foregroundColor', ''], ['backgroundColor', ''], ['linkColor', ''], ['errorColor', ''], ['primaryButtonForegroundColor', ''], ['primaryButtonBackgroundColor', ''], ['secondaryButtonForegroundColor', ''], ['secondaryButtonBackgroundColor', '']];

        setTimeout(() => {
            const script = document.createElement('script');
            script.src = 'https://reservation.dish.co/widget.js';
            script.async = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }, 1000);
    }, []);
    return (
        <LayoutComponent headerHidden={false}>
            <>
                <div className={"container-booking"}>
                    <section className="wrapper">
                        <div className="top">Tisch reservieren</div>
                        <div className="bottom" aria-hidden="true">Tisch reservieren</div>
                    </section>
                    <div id="hors-hydra-9d590097-0512-4cac-b8f8-985d65b796ff"></div>
                </div>
                <Footer textColor={"text-white"}/>
            </>
        </LayoutComponent>
    )


}


export default Booking;