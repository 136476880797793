import LayoutComponent from "../components/layout/LayoutComponent";
import moment from 'moment'
import HTMLFlipBook from 'react-pageflip';

import Footer, {getWindowDimensions} from "../components/Footer";
import React, {useCallback, useEffect, useState} from "react";
import '../styles/Menu.css';
import gsap from "gsap";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, EffectFade, Navigation, Pagination, Scrollbar} from "swiper/modules";

function Menu(props) {
    let urls = [
"https://i.ibb.co/BynY6xG/1.jpg" ,
"https://i.ibb.co/cL3X4x1/2.jpg" ,
"https://i.ibb.co/KDQrGgj/3.jpg" ,
"https://i.ibb.co/YpR2QWb/4.jpg" ,
"https://i.ibb.co/WPKMx77/5.jpg" ,
"https://i.ibb.co/LRfzjNZ/6.jpg" ,
"https://i.ibb.co/kHjPZ1c/7.jpg" ,
"https://i.ibb.co/TWqnvJ6/8.jpg" ,
"https://i.ibb.co/M83MFTm/9.jpg" ,
"https://i.ibb.co/ZJCsL92/10.jpg",
"https://i.ibb.co/vsfKT64/11.jpg",
"https://i.ibb.co/52rCmk4/12.jpg",
"https://i.ibb.co/dWDxrBS/13.jpg",
"https://i.ibb.co/XCsLbNV/14.jpg",
"https://i.ibb.co/zZg5bw0/15.jpg",
"https://i.ibb.co/bNQDJQP/16.jpg",
"https://i.ibb.co/f8tZCxN/17.jpg",
"https://i.ibb.co/M5sY6md/18.jpg",
"https://i.ibb.co/0Vx1dfL/19.jpg",
"https://i.ibb.co/nLm5jBm/20.jpg",
"https://i.ibb.co/H7pS581/21-bia.jpg",
"https://i.ibb.co/SxQGFxf/22.jpg",
"https://i.ibb.co/S6TTn7p/23.jpg",
"https://i.ibb.co/6y3GM7x/24.jpg",
"https://i.ibb.co/4SqKSFV/25.jpg",
"https://i.ibb.co/TP5TG7x/26.jpg",
"https://i.ibb.co/L6H8VhK/27.jpg",
"https://i.ibb.co/7K6jTYr/28.jpg",
"https://i.ibb.co/wKryqgs/29.jpg",
"https://i.ibb.co/rHhynqx/30.jpg",
"https://i.ibb.co/vsKJfBh/31.jpg",
"https://i.ibb.co/3WBSQ0c/32.jpg",
"https://i.ibb.co/gFhfbgr/33.jpg",
"https://i.ibb.co/Y71CT2h/34.jpg",
"https://i.ibb.co/P6G0GfH/35.jpg",
"https://i.ibb.co/d5XBhJM/36.jpg",
"https://i.ibb.co/yXBdn7m/37.jpg",
              ]
    ;
    let isMobile = false;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if (windowDimensions?.width <= 1240) {
        isMobile = true
    }

    // const [isInHour, setIsInHour] = useState(false);
    const [timeActive, setTimeActive] = useState("");

    let hours = [
        {
            day: "Montag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        }, {
            day: "Dienstag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        }, {
            day: "Mittwoch",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        },
        {
            day: "Donnerstag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        },
        {
            day: "Freitag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        },
        {
            day: "Samstag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        },
        {
            day: "Sonntag",
            hours: "11.00 - 15.00 | 17.00 - 22.00Uhr",
        },

    ];
    useEffect(() => {
        // const format = 'hh:mm:ss';
        // const time = moment();
        // const beforeTime = moment('11:00:00', format);
        // const afterTime = moment('15:00:00', format);
        // const beforeTimeAf = moment('11:00:00', format);
        // const afterTimeAf = moment('15:00:00', format);
        // if (time.isBetween(beforeTime, afterTime) || time.isBetween(beforeTimeAf, afterTimeAf)) {
        //     setIsInHour(true)
        //     if (time.isBetween(beforeTime, afterTime)) {
        //         setTimeActive("11.00 - 15.00")
        //     } else {
        //         setTimeActive("17.00 - 22.00")
        //     }
        // } else {
        //     setIsInHour(false)
        // }
    }, []);

    useEffect(() => {
        let effectStyle = {
            opacity: 1,
            x: 0,
            y: 0,
            ease: "power3.inOut",
            delay: 0.4,
            stagger: 0.2
        }
        gsap.fromTo(".times > .item-time", {
            opacity: 0,
            x: 500,
        }, {
            opacity: 1,
            duration: 3,
            x: 0,
            stagger: 0.1,
            ease: "back"
        })

        gsap.to(".swiper > .swiper-button-next-custom", 2, {...effectStyle, x: -10})
        gsap.to(".swiper > .swiper-button-prev-custom", 2, effectStyle)
    }, [isMobile])

    return (
        <LayoutComponent headerHidden={false}>
            <>
                <div className="bg-wood">
                    <div className="menu-food">
                        <section className="wrapper">
                            <div className="top">Speisekarte</div>
                            <div className="bottom" aria-hidden="true">Speisekarte</div>
                        </section>
                        {
                            !isMobile ? (
                                <HTMLFlipBook
                                    width={600}
                                    height={800}
                                    minWidth={350}
                                    maxWidth={1000}
                                    minHeight={420}
                                    maxHeight={1350}
                                    showCover={true}

                                    flippingTime={1000}

                                    maxShadowOpacity={0.5}
                                    className="album-web"
                                >

                                    {urls.map((url, index) => {
                                        let number = index + 1;
                                        return (

                                            <Page number={number}>
                                                <img src={url} alt=""/>
                                            </Page>
                                        )
                                    })}

                                </HTMLFlipBook>
                            ) : <Swiper
                                spaceBetween={50}
                                modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                                slidesPerView={1}

                                effect={"creative"}
                                loop={true}
                                speed={1500}
                                navigation={{
                                    nextEl: '.swiper-button-next-custom',
                                    prevEl: '.swiper-button-prev-custom'
                                }}
                                pagination={false}
                                scrollbar={{
                                    el: '.swiper-scrollbar-custom',
                                    draggable: true,
                                    dragSize: 170
                                }}
                                onSwiper={(swiper) => {
                                }}

                            >

                                {
                                    urls.map((url, index) => {

                                        return (
                                            <SwiperSlide>
                                                <div className={"menu-img"}>
                                                    <img src={url} alt=""/>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                                <div className="swiper-pagination"></div>

                                <div className="swiper-button-prev-custom">
                                    PREV
                                </div>
                                <div className="swiper-button-next-custom">
                                    NEXT
                                </div>
                            </Swiper>
                        }
                    </div>

                    <section className="wrapper">
                        <div className="top">Öffnungszeiten</div>
                        <div className="bottom" aria-hidden="true">Öffnungszeiten</div>
                    </section>
                    <div className="card-opening-hours">
                        <div className="open-title">
                            <div className="wrap-time-title">
                                <div className={"time-title"}>
                                    <h2>Öffnungszeiten</h2>
                                    <div className="time-now">


                                    </div>
                                </div>
                                <div className="time-kitchen-close">
                                    Küchenschluss: 21:30 Uhr
                                </div>
                            </div>

                        </div>
                        <div className="times">
                            {hours?.map((el) => {
                                return (
                                    <div className="item-time">
                                        <div className="day">
                                            {el?.day}
                                        </div>
                                        <div className="hours">
                                            {el?.hours}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <Footer textColor={"text-white"}/>
                </div>

            </>
        </LayoutComponent>
    )


}


const Page = React.forwardRef((props, ref) => {
    return (
        <div className="page" ref={ref}>

            <div className="page-content">
                {props.children}
            </div>
            <p>{props.number}</p>
        </div>
    );
});
export default Menu;